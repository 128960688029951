<template>
  <a-card :bordered="false" class="card-area">

    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="产品名称">
              <a-input v-model="queryParams.name" placeholder="产品名称" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="产品类型">
              <a-select v-model="queryParams.productType">
                <a-select-option v-for="item in productTypeArr" :key="item.dictId" :value="item.dictId">{{item.valuee}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
				<a-row>
				  <a-col :span="12"> </a-col>
				  <a-col :span="12" :style="{ textAlign: 'right' }">
				    <a-button type="primary" @click="search">查询</a-button>
				    <a-button style="margin-left: 8px" @click="reset">重置</a-button>
				  </a-col>
				</a-row>
      </a-form>
    </div>
    
    <div class="operator">
      <a-button type="primary" ghost @click="addRecord">新增</a-button>
      <a-button @click="batchDelete" >删除</a-button>
    </div>
    <a-table ref="TableInfo" :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" :pagination="pagination" @change="handleTableChange" bordered :loading="loading" :columns="columns" :data-source="dataSource">
      <a-tag :color="text=='1'?'red':'orange'" slot="isStar" slot-scope="text">{{ text=='1'?"是":"否" }}</a-tag>
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editRecord(record)" title="修改"></a-icon>
      </template>
    </a-table>
    <ProductEdit ref="productEdit" @close="handleEditClose" @success="handleEditSuccess" :editVisiable="editVisiable" />
    <ProductAdd ref="productAdd" @close="handleAddClose" @success="handleAddSuccess" :addVisiable="addVisiable" />
  </a-card>
</template>

<script>
import ProductAdd from "./ProductAdd"
import ProductEdit from "./ProductEdit"

const columns = [
  {
    title: "产品名称",
    dataIndex: "name",
    ellipsis: true
  },
  {
    title: "类型",
    dataIndex: "productTypeName",
    ellipsis: true
  },
  // {
  //   title: "产品图片",
  //   dataIndex: "image",
  //   ellipsis: true
  // },
  {
    title: "产品描述",
    dataIndex: "desc",
    ellipsis: true
  },
  {
    title: "明星产品",
    dataIndex: "isStar",
    scopedSlots: { customRender: 'isStar' },
    ellipsis: true
  },
  // {
  //   title: "附件详情",
  //   dataIndex: "enclosure",
  //   ellipsis: true
  // },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    fixed: "right",
  }
];


export default {
  components: {ProductAdd, ProductEdit},
  data() {
    return {
      columns,
      dataSource: [],
      selectedRowKeys: [],
      productTypeArr: [],
      queryParams: {
        name: null,
        productType: null
      },
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
      editVisiable: false,
      addVisiable: false
    }
  },
  mounted() {
    this.fetch();
    this.productTypeArr = this.$store.state.dict.dicts.product_product_type
  },
  methods: {
    editRecord(record) {
      this.editVisiable = true;
      this.$refs.productEdit.setFormFields(record);
    },
    handleEditClose() {
      this.editVisiable = false;
    },
    handleEditSuccess() {
      this.editVisiable = false;
      this.$message.success("修改产品相关成功！");
      this.search();
    },
    addRecord() {
      this.addVisiable = true;
    },
    handleAddClose() {
      this.addVisiable = false;
    },
    handleAddSuccess() {
      this.addVisiable = false;
      this.$message.success("添加产品成功！");
      this.search();
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    onSelectChange (selectedRowKeys) {
      // console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    search () {
      this.fetch({
        ...this.queryParams
      })
    },
    fetch(params = {}) {
      this.loading = true
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get('product', {
        ...params
      }).then((r) => {
        console.log("产品==>", r.data);
        const pagination = { ...this.pagination };
        pagination.total = r.data.total;
        let rows = r.data.rows;
        if(rows) {
          for (const key in rows) {
            rows[key]['key'] = +rows[key]['productId'];
          }
        }
        this.dataSource = rows;
        this.pagination = pagination;
        this.loading = false;
      })
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`product/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.fetch();
              })
              .catch(e => console.log("产品删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    },
		reset() {
		  // 取消选中
		  this.selectedRowKeys = [];
		  // 重置分页
		  this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
		  if (this.paginationInfo) {
		    this.paginationInfo.current = this.pagination.defaultCurrent;
		    this.paginationInfo.pageSize = this.pagination.defaultPageSize;
		  }
		  // 重置列过滤器规则
		  this.filteredInfo = null;
		  // 重置列排序规则
		  this.sortedInfo = null;
		  // 重置查询参数
		  this.queryParams = {
		    name: null,
		    productType: null
		  },
		  this.fetch();
		},
  }
}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>